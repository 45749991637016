import React, { FC } from 'react';

interface CapQuestLogoProps {
  size?: number;
}

const CapQuestLogo: FC<CapQuestLogoProps> = ({ size }) => {
  return (
    <svg
      fill="none"
      height={`${size || 56}`}
      viewBox="0 0 56 56"
      width={`${size || 56}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.7224 45.4138C34.8587 47.0126 31.6449 47.8805 28.3656 47.9407C25.0864 48.0008 21.8429 47.2513 18.9225 45.7584C16.0021 44.2656 13.4949 42.0756 11.6231 39.3824C9.75121 36.6892 8.57245 33.576 8.1912 30.3184C7.80993 27.0608 8.23795 23.7595 9.43732 20.7068C10.6367 17.6542 12.5704 14.9444 15.0672 12.8176C17.5639 10.6908 20.5467 9.21253 23.7512 8.51381C26.9557 7.81509 30.2831 7.91746 33.4386 8.81184L31.8416 14.4464C29.6127 13.8146 27.2624 13.7423 24.9988 14.2358C22.7353 14.7294 20.6284 15.7736 18.8648 17.2758C17.1012 18.7781 15.7354 20.6922 14.8882 22.8484C14.041 25.0047 13.7387 27.3366 14.008 29.6376C14.2773 31.9386 15.1099 34.1377 16.4321 36.04C17.7543 37.9424 19.5252 39.4893 21.5881 40.5438C23.6509 41.5982 25.9419 42.1276 28.2583 42.0852C30.5746 42.0427 32.8447 41.4296 34.8675 40.3003L37.7224 45.4138Z"
        fill="#D0D5DD"
      />
      <path
        d="M35.9512 9.70783C40.4888 11.6805 44.1311 15.2694 46.1706 19.7773C48.2101 24.2853 48.5011 29.3904 46.9871 34.1009L41.3977 32.3044C42.466 28.9806 42.2606 25.3783 40.8215 22.1974C39.3824 19.0165 36.8123 16.4841 33.6105 15.0921L35.9512 9.70783Z"
        fill="#344054"
      />
      <path
        d="M49.0822 37.8814C47.7811 41.3 45.565 44.2949 42.6762 46.5386L37.0599 39.3076C38.6226 38.0939 39.8213 36.4739 40.5251 34.6247L49.0822 37.8814Z"
        fill="#344054"
      />
    </svg>
  );
};

export default CapQuestLogo;
