import React from 'react';

const BellIcon = () => {
  return (
    <svg fill="none" height="16" viewBox="0 0 17 16" width="17" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.83353 14.0002H7.16687M2.02943 3.88009C2.01986 2.91253 2.54152 2.00899 3.38423 1.53349M14.9683 3.8801C14.9779 2.91253 14.4563 2.009 13.6135 1.5335M12.5002 5.3335C12.5002 4.27263 12.0788 3.25521 11.3286 2.50507C10.5785 1.75492 9.56106 1.3335 8.5002 1.3335C7.43933 1.3335 6.42192 1.75492 5.67177 2.50507C4.92163 3.25521 4.5002 4.27263 4.5002 5.3335C4.5002 7.39362 3.98051 8.80413 3.39998 9.7371C2.91028 10.5241 2.66544 10.9176 2.67442 11.0273C2.68436 11.1489 2.71011 11.1952 2.80805 11.2679C2.8965 11.3335 3.29526 11.3335 4.09277 11.3335H12.9076C13.7051 11.3335 14.1039 11.3335 14.1923 11.2679C14.2903 11.1952 14.316 11.1489 14.326 11.0273C14.335 10.9176 14.0901 10.5241 13.6004 9.73711C13.0199 8.80413 12.5002 7.39362 12.5002 5.3335Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </svg>
  );
};

export default BellIcon;
