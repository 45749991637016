import React, { useState } from 'react';

import {
  BellIcon,
  CalendarIcon,
  CapQuestLogo,
  CoinsHandIcon,
  CoinsStackedIcon,
  EnterIcon,
  PieChartIcon,
} from '../../assets/icons';
import DataIcon from '../../assets/icons/DataIcon';
import TickIcon from '../../assets/icons/TickIcon';
import bgBig from '../../assets/images/BG-big.png';
import bgSmall from '../../assets/images/BG-small.png';
import capquestScreenshot from '../../assets/images/capquest-screenshot.png';
import footerGraphic from '../../assets/images/footer-graphic.png';
import Chip from '../../components/Chip';
import CircleLoader from '../../components/CircleLoader';
import Header from '../../components/Header';
import Input from '../../components/Input';
import useRedirectToHttps from '../../hooks/useRedirectToHttps';

const Home = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useRedirectToHttps();

  const handleSendEmail = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/waitlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!res.ok) throw new Error((await res.json()).message);
      setErrorMessage('');
      setIsEmailSent(true);
    } catch (e) {
      let message = 'Unexpected error';
      if (e instanceof Error) {
        message = e.message;
      }
      setErrorMessage(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-center font-inter text-sm font-normal text-gray-700">
      <Header />
      <main className="flex flex-col gap-16">
        <div className="relative flex flex-col gap-16 pt-[118px]">
          <div className="absolute left-0 top-0 -z-1 h-full w-full bg-[url]">
            <img alt="" className="hidden h-[calc(100%+64px)] w-full lg:block" src={bgBig} />
            <img alt="" className="h-[calc(100%+64px)] w-full lg:hidden" src={bgSmall} />
          </div>
          <section className="flex w-full justify-center px-4 lg:px-6">
            <div className="flex max-w-[623px] flex-col gap-8">
              <div className="flex flex-col items-center gap-6">
                <Chip>Coming soon</Chip>
                <div className="text-[30px] font-bold leading-[42px] lg:text-[56px] lg:leading-[72px]">
                  Simplified cap tables & investor relations
                </div>
                <div className="max-w-[538px] lg:text-[16px] lg:leading-6">
                  Complete SaaS for managing equity, employee plans, venture debt, and other assets,
                  enhancing efficiency and governance.
                </div>
              </div>

              <div className="flex flex-col items-center gap-6">
                <div className="w-full max-w-[394px]">
                  <Input
                    disabled={isEmailSent}
                    errorMessage={errorMessage}
                    icon={isLoading ? <CircleLoader /> : isEmailSent ? <TickIcon /> : <EnterIcon />}
                    isSuccess={isEmailSent}
                    onChange={setEmail}
                    onIconClick={isLoading || isEmailSent ? undefined : handleSendEmail}
                    placeholder="Business email"
                    type="email"
                    value={email}
                  />
                </div>

                <div className="flex items-center gap-2 rounded-[16px] bg-brand-25 px-3 py-1.5 text-xs font-[450] text-brand-700">
                  <BellIcon />
                  <div>We will notify you when CapQuest is launched</div>
                </div>
              </div>
            </div>
          </section>
          <div className="flex justify-center">
            <div className="m-4 max-w-[1152px] overflow-hidden rounded-[7.52px] border-[1px] border-gray-200 shadow-md lg:m-6 lg:rounded-[20px]">
              <img alt="CapQuest Demo" src={capquestScreenshot} />
            </div>
          </div>

          <div className="flex flex-col items-center gap-6 px-4 lg:px-6" id="what-we-do">
            <div className="text-[28px] font-bold leading-[40px] lg:text-[40px] lg:leading-[54px]">
              What we do
            </div>
            <div className="w-full max-w-[650px] text-sm lg:text-[16px] lg:leading-6">
              We empower and align shareholders and employees through complete transparency of
              ownership with personalized dashboards and metrics.
            </div>
          </div>
        </div>
        <section className="flex justify-center px-4 lg:px-6">
          <div
            className="w-full max-w-[1152px] rounded-[32px] bg-gray-50 p-4"
            style={{ boxShadow: '0px 0px 8px 0px #34405414 inset' }}
          >
            <div
              className="flex flex-col rounded-[20px] bg-white py-8 lg:flex-row"
              style={{ boxShadow: '0px 0px 16px 0px #47546714' }}
            >
              <div className="flex w-full flex-col gap-6 border-b border-gray-100 p-4 lg:border-b-0 lg:border-r">
                <div className="flex flex-col items-center gap-2">
                  <div
                    className="bg-clip-text text-[28px] font-bold leading-10 text-transparent lg:text-4xl"
                    style={{
                      backgroundImage: 'linear-gradient(180deg, #083D90 0%, #3E7BDA 100%)',
                    }}
                  >
                    Manage
                  </div>
                  <div className="w-fit rounded-[32px] bg-brand-25 px-3 py-[3px] text-label-md font-[450] text-brand-700">
                    Cap Table management
                  </div>
                </div>
                <div>A single source of truth for your investors and employees</div>
              </div>
              <div className="flex w-full flex-col gap-6 border-b border-gray-100 p-4 lg:border-b-0 lg:border-r">
                <div className="flex flex-col items-center gap-2">
                  <div
                    className="bg-clip-text text-[28px] font-bold leading-10 text-transparent lg:text-4xl"
                    style={{
                      backgroundImage: 'linear-gradient(180deg, #027A48 0%, #12B76A 100%)',
                    }}
                  >
                    Raise
                  </div>
                  <div className="w-fit rounded-[32px] bg-forest-25 px-3 py-[3px] text-label-md font-[450] text-forest-600">
                    Fundraising toolkit
                  </div>
                </div>
                <div>Fundraising tracker and a data room for more efficient fundraising</div>
              </div>
              <div className="flex w-full flex-col gap-6 border-b border-gray-100 p-4 lg:border-b-0 lg:border-r">
                <div className="flex flex-col items-center gap-2">
                  <div
                    className="bg-clip-text text-[28px] font-bold leading-10 text-transparent lg:text-4xl"
                    style={{
                      backgroundImage: 'linear-gradient(180deg, #B54708 0%, #F79009 100%)',
                    }}
                  >
                    Govern
                  </div>
                  <div className="w-fit rounded-[32px] bg-sunbeam-50 px-3 py-[3px] text-label-md font-[450] text-sunbeam-600">
                    Digital corporate governance
                  </div>
                </div>
                <div>Providing compliance checklists and a reporting toolkit</div>
              </div>
              <div className="flex w-full flex-col gap-6 border-gray-100 p-4">
                <div className="flex flex-col items-center gap-2">
                  <div
                    className="bg-clip-text text-[28px] font-bold leading-10 text-transparent lg:text-4xl"
                    style={{
                      backgroundImage: 'linear-gradient(180deg, #B42318 0%, #F97066 100%)',
                    }}
                  >
                    Empower
                  </div>
                  <div className="w-fit rounded-[32px] bg-fireside-25 px-3 py-[3px] text-label-md font-[450] text-fireside-600">
                    Employee engagement
                  </div>
                </div>
                <div>Empower employees: Build ownership culture and attract talent</div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="flex flex-col items-center gap-16 bg-gray-50 px-4 py-16 lg:px-6"
          id="features"
        >
          <div className="flex w-full max-w-[650px] flex-col items-center gap-6">
            <div className="text-[28px] font-bold leading-[40px] lg:text-[40px] lg:leading-[54px]">
              Features
            </div>
            <div className="text-sm lg:text-[16px] lg:leading-6">
              Our core products are centered around Cap Table management, helping founders manage
              equity funding, employee plans, and debt financing
            </div>
          </div>

          <div className="flex w-full max-w-[1152px] flex-col gap-4 lg:flex-row lg:flex-wrap">
            <div
              className="flex w-full flex-col items-center gap-6 rounded-[22px] border-r border-gray-100 bg-white px-4 py-6 lg:max-w-[calc(33.333333333%-(32px/3))]"
              style={{
                boxShadow: '0px 0px 12px 0px #1018280D',
              }}
            >
              <div
                className="flex h-[88px] w-[88px] items-center justify-center rounded-[22px]"
                style={{
                  boxShadow:
                    '2.75px 2.75px 15.1px 0px #0000001A, -2.75px -2.75px 8.24px 0px #FFFFFF',
                }}
              >
                <DataIcon />
              </div>
              <div className="text-xl font-[550]">Share classes</div>
              <div>
                A single source of truth for investors and employees, enabling structured equity
                shares with specific rights and privileges.
              </div>
            </div>
            <div
              className="flex w-full flex-col items-center gap-6 rounded-[22px] border-r border-gray-100 bg-white px-4 py-6 lg:max-w-[calc(33.333333333%-(32px/3))]"
              style={{
                boxShadow: '0px 0px 12px 0px #1018280D',
              }}
            >
              <div
                className="flex h-[88px] w-[88px] items-center justify-center rounded-[22px]"
                style={{
                  boxShadow:
                    '2.75px 2.75px 15.1px 0px #0000001A, -2.75px -2.75px 8.24px 0px #FFFFFF',
                }}
              >
                <PieChartIcon />
              </div>
              <div className="text-xl font-[550]">Pool & Plans</div>
              <div>
                Enable founders to allocate and manage equity pools for Employee stock option plans
                and other incentives programs.
              </div>
            </div>
            <div
              className="flex w-full flex-col items-center gap-6 rounded-[22px] border-r border-gray-100 bg-white px-4 py-6 lg:max-w-[calc(33.333333333%-(32px/3))]"
              style={{
                boxShadow: '0px 0px 12px 0px #1018280D',
              }}
            >
              <div
                className="flex h-[88px] w-[88px] items-center justify-center rounded-[22px]"
                style={{
                  boxShadow:
                    '2.75px 2.75px 15.1px 0px #0000001A, -2.75px -2.75px 8.24px 0px #FFFFFF',
                }}
              >
                <CoinsHandIcon />
              </div>
              <div className="text-xl font-[550]">Venture Debt</div>
              <div>
                Streamlined tracking of venture debt obligations and terms, maintaining clear
                oversight of financial commitments and equity financing.
              </div>
            </div>
            <div
              className="flex w-full flex-col items-center gap-6 rounded-[22px] border-r border-gray-100 bg-white px-4 py-6 lg:max-w-[calc(50%-8px)]"
              style={{
                boxShadow: '0px 0px 12px 0px #1018280D',
              }}
            >
              <div
                className="flex h-[88px] w-[88px] items-center justify-center rounded-[22px]"
                style={{
                  boxShadow:
                    '2.75px 2.75px 15.1px 0px #0000001A, -2.75px -2.75px 8.24px 0px #FFFFFF',
                }}
              >
                <CoinsStackedIcon />
              </div>
              <div className="text-xl font-[550]">Cap Table</div>
              <div>
                Comprehensive up-to-date and historical view of the company&apos;s equity ownership.
              </div>
            </div>
            <div
              className="flex w-full flex-col items-center gap-6 rounded-[22px] border-r border-gray-100 bg-white px-4 py-6 lg:max-w-[calc(50%-8px)]"
              style={{
                boxShadow: '0px 0px 12px 0px #1018280D',
              }}
            >
              <div
                className="flex h-[88px] w-[88px] items-center justify-center rounded-[22px]"
                style={{
                  boxShadow:
                    '2.75px 2.75px 15.1px 0px #0000001A, -2.75px -2.75px 8.24px 0px #FFFFFF',
                }}
              >
                <CalendarIcon />
              </div>
              <div className="text-xl font-[550]">Scenario Modeling</div>
              <div>
                Allows founders to simulate various financing scenarios and their impact on
                ownership and dilution.
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="flex flex-col items-center gap-8 px-4 pt-16 lg:px-6" id="contact">
        <div className="flex flex-col items-center gap-4">
          <CapQuestLogo />

          <Chip>Coming soon</Chip>

          <div className="text-[28px] font-bold leading-[40px] lg:text-[40px] lg:leading-[54px]">
            Join the waitlist
          </div>
        </div>

        <div className="flex flex-col items-center gap-6">
          <div className="w-full max-w-[394px]">
            <Input
              disabled={isEmailSent}
              errorMessage={errorMessage}
              icon={isLoading ? <CircleLoader /> : isEmailSent ? <TickIcon /> : <EnterIcon />}
              isSuccess={isEmailSent}
              onChange={setEmail}
              onIconClick={isLoading || isEmailSent ? undefined : handleSendEmail}
              placeholder="Business email"
              type="email"
              value={email}
            />
          </div>

          <div className="flex items-center gap-2 rounded-[16px] bg-brand-25 px-3 py-1.5 text-xs font-[450] text-brand-700">
            <BellIcon />
            <div>We will notify you when CapQuest is launched</div>
          </div>
          <div className="relative">
            <img alt="" src={footerGraphic} />
            <div className="absolute bottom-[18px] left-1/2 z-1 w-[230px] -translate-x-1/2 text-label-sm text-gray-400">
              © Copyright {new Date().getFullYear()} CapQuest. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
