import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import App from './App';
import './index.css';
import router from './Router';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    {/* <React.StrictMode> */}
    <App>
      <RouterProvider router={router} />
    </App>
    {/* </React.StrictMode> */}
  </>,
);
