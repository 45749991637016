import React from 'react';

const CalendarIcon = () => {
  return (
    <svg fill="none" height="41" viewBox="0 0 41 41" width="41" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.5 16.7801H5.5M27.1667 3.44678V10.1134M13.8333 3.44678V10.1134M13.5 36.7801H27.5C30.3003 36.7801 31.7004 36.7801 32.77 36.2351C33.7108 35.7558 34.4757 34.9909 34.955 34.0501C35.5 32.9805 35.5 31.5804 35.5 28.7801V14.7801C35.5 11.9798 35.5 10.5797 34.955 9.51016C34.4757 8.56935 33.7108 7.80444 32.77 7.32508C31.7004 6.78011 30.3003 6.78011 27.5 6.78011H13.5C10.6997 6.78011 9.29961 6.78011 8.23005 7.32508C7.28924 7.80444 6.52433 8.56935 6.04497 9.51016C5.5 10.5797 5.5 11.9798 5.5 14.7801V28.7801C5.5 31.5804 5.5 32.9805 6.04497 34.0501C6.52433 34.9909 7.28924 35.7558 8.23005 36.2351C9.29961 36.7801 10.6997 36.7801 13.5 36.7801Z"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  );
};

export default CalendarIcon;
