import React, { FC, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface AppProps {
  children: ReactNode;
}

const App: FC<AppProps> = ({ children }) => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">{children}</div>
      <ToastContainer closeOnClick position="bottom-center" />
    </QueryClientProvider>
  );
};

export default App;
