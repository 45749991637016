import React from 'react';

import LoaderIcon from '../../assets/icons/LoaderIcon';

const CircleLoader = () => {
  return (
    <div className="animate-spin">
      <LoaderIcon />
    </div>
  );
};

export default CircleLoader;
