import React from 'react';

import { CapQuestLogo } from '../../assets/icons';

const headerLinks = [
  { link: '#what-we-do', title: 'What we do' },
  { link: '#features', title: 'Features' },
  { link: '#contact', title: 'Contact' },
];

const Header = () => {
  return (
    <header className="flex w-full items-center justify-center border-b border-gray-100 px-4 py-2">
      <div className="flex h-full w-full max-w-[1280px] items-center justify-between">
        <div className="flex items-center">
          <CapQuestLogo />
          <div className="text-xl font-[550] text-gray-700">CapQuest</div>
        </div>

        <div className="flex items-center gap-8">
          {headerLinks.map(({ link, title }, i) => (
            <a
              className={`font-[450] ${i === 2 ? '' : 'hidden'} lg:block`}
              href={link}
              key={`link-${i}`}
            >
              {title}
            </a>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
