import React from 'react';

const CoinsHandIcon = () => {
  return (
    <svg fill="none" height="41" viewBox="0 0 41 41" width="41" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.0491 14.0332C22.0951 14.7134 20.9276 15.1134 19.6666 15.1134C16.445 15.1134 13.8333 12.5018 13.8333 9.28011C13.8333 6.05845 16.445 3.44678 19.6666 3.44678C21.755 3.44678 23.587 4.54414 24.6175 6.19368M10.5 33.592H14.8505C15.4177 33.592 15.9815 33.6595 16.5313 33.7945L21.1281 34.9116C22.1256 35.1546 23.1646 35.1782 24.1724 34.9824L29.2549 33.9936C30.5976 33.7321 31.8326 33.0892 32.8005 32.1476L36.3965 28.6496C37.4234 27.6524 37.4234 26.0342 36.3965 25.0352C35.4719 24.1358 34.0079 24.0346 32.9619 24.7973L28.771 27.8549C28.1708 28.2936 27.4405 28.5298 26.6894 28.5298H22.6425L25.2184 28.5297C26.6703 28.5297 27.8464 27.3857 27.8464 25.9733V25.462C27.8464 24.2893 27.0259 23.2667 25.8568 22.9832L21.881 22.0163C21.2339 21.8594 20.5713 21.7801 19.9052 21.7801C18.2972 21.7801 15.3865 23.1115 15.3865 23.1115L10.5 25.1549M33.8333 10.9468C33.8333 14.1684 31.2216 16.7801 28 16.7801C24.7783 16.7801 22.1666 14.1684 22.1666 10.9468C22.1666 7.72512 24.7783 5.11344 28 5.11344C31.2216 5.11344 33.8333 7.72512 33.8333 10.9468ZM3.83331 24.4468L3.83331 34.1134C3.83331 35.0469 3.83331 35.5136 4.01497 35.8701C4.17476 36.1837 4.42973 36.4387 4.74333 36.5985C5.09985 36.7801 5.56656 36.7801 6.49998 36.7801H7.83331C8.76673 36.7801 9.23344 36.7801 9.58996 36.5985C9.90357 36.4387 10.1585 36.1837 10.3183 35.8701C10.5 35.5136 10.5 35.0469 10.5 34.1134V24.4468C10.5 23.5134 10.5 23.0466 10.3183 22.6901C10.1585 22.3765 9.90357 22.1216 9.58996 21.9618C9.23344 21.7801 8.76673 21.7801 7.83331 21.7801L6.49998 21.7801C5.56656 21.7801 5.09985 21.7801 4.74333 21.9618C4.42973 22.1216 4.17476 22.3765 4.01497 22.6901C3.83331 23.0466 3.83331 23.5134 3.83331 24.4468Z"
        stroke="#344054"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  );
};

export default CoinsHandIcon;
