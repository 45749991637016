import React from 'react';

const DataIcon = () => {
  return (
    <svg fill="none" height="41" viewBox="0 0 41 41" width="41" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.8333 36.7801C36.3 36.7801 36.5334 36.7801 36.7116 36.6893C36.8684 36.6094 36.9959 36.4819 37.0758 36.3251C37.1666 36.1468 37.1666 35.9135 37.1666 35.4468V18.1134C37.1666 17.6467 37.1666 17.4134 37.0758 17.2351C36.9959 17.0783 36.8684 16.9508 36.7116 16.8709C36.5334 16.7801 36.3 16.7801 35.8333 16.7801L31.8333 16.7801C31.3666 16.7801 31.1332 16.7801 30.955 16.8709C30.7982 16.9508 30.6707 17.0783 30.5908 17.2351C30.5 17.4134 30.5 17.6467 30.5 18.1134V22.1134C30.5 22.5802 30.5 22.8135 30.4092 22.9918C30.3293 23.1486 30.2018 23.2761 30.045 23.356C29.8667 23.4468 29.6334 23.4468 29.1666 23.4468H25.1666C24.6999 23.4468 24.4666 23.4468 24.2883 23.5376C24.1315 23.6175 24.004 23.745 23.9241 23.9018C23.8333 24.08 23.8333 24.3134 23.8333 24.7801V28.7801C23.8333 29.2468 23.8333 29.4802 23.7425 29.6584C23.6626 29.8152 23.5351 29.9427 23.3783 30.0226C23.2 30.1134 22.9667 30.1134 22.5 30.1134H18.5C18.0333 30.1134 17.7999 30.1134 17.6217 30.2043C17.4649 30.2842 17.3374 30.4117 17.2575 30.5685C17.1666 30.7467 17.1666 30.9801 17.1666 31.4468V35.4468C17.1666 35.9135 17.1666 36.1468 17.2575 36.3251C17.3374 36.4819 17.4649 36.6094 17.6217 36.6893C17.7999 36.7801 18.0333 36.7801 18.5 36.7801L35.8333 36.7801Z"
        stroke="#2565C8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M17.1666 11.4468C17.1666 10.9801 17.1666 10.7467 17.2575 10.5685C17.3374 10.4117 17.4649 10.2842 17.6217 10.2043C17.7999 10.1134 18.0333 10.1134 18.5 10.1134H22.5C22.9667 10.1134 23.2 10.1134 23.3783 10.2043C23.5351 10.2842 23.6626 10.4117 23.7425 10.5685C23.8333 10.7467 23.8333 10.9801 23.8333 11.4468V15.4468C23.8333 15.9135 23.8333 16.1468 23.7425 16.3251C23.6626 16.4819 23.5351 16.6094 23.3783 16.6893C23.2 16.7801 22.9667 16.7801 22.5 16.7801H18.5C18.0333 16.7801 17.7999 16.7801 17.6217 16.6893C17.4649 16.6094 17.3374 16.4819 17.2575 16.3251C17.1666 16.1468 17.1666 15.9135 17.1666 15.4468V11.4468Z"
        stroke="#2565C8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M5.49998 21.4468C5.49998 20.9801 5.49998 20.7467 5.59081 20.5685C5.6707 20.4117 5.79819 20.2842 5.95499 20.2043C6.13325 20.1134 6.3666 20.1134 6.83331 20.1134H10.8333C11.3 20.1134 11.5334 20.1134 11.7116 20.2043C11.8684 20.2842 11.9959 20.4117 12.0758 20.5685C12.1666 20.7467 12.1666 20.9801 12.1666 21.4468V25.4468C12.1666 25.9135 12.1666 26.1468 12.0758 26.3251C11.9959 26.4819 11.8684 26.6094 11.7116 26.6893C11.5334 26.7801 11.3 26.7801 10.8333 26.7801H6.83331C6.3666 26.7801 6.13325 26.7801 5.95499 26.6893C5.79819 26.6094 5.6707 26.4819 5.59081 26.3251C5.49998 26.1468 5.49998 25.9135 5.49998 25.4468V21.4468Z"
        stroke="#2565C8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
      <path
        d="M3.83331 4.78011C3.83331 4.3134 3.83331 4.08005 3.92414 3.90179C4.00404 3.74498 4.13152 3.6175 4.28832 3.53761C4.46658 3.44678 4.69994 3.44678 5.16665 3.44678H9.16665C9.63336 3.44678 9.86671 3.44678 10.045 3.53761C10.2018 3.6175 10.3293 3.74498 10.4092 3.90179C10.5 4.08005 10.5 4.3134 10.5 4.78011V8.78011C10.5 9.24682 10.5 9.48018 10.4092 9.65844C10.3293 9.81524 10.2018 9.94272 10.045 10.0226C9.86671 10.1134 9.63336 10.1134 9.16665 10.1134H5.16665C4.69994 10.1134 4.46658 10.1134 4.28832 10.0226C4.13152 9.94272 4.00404 9.81524 3.92414 9.65844C3.83331 9.48018 3.83331 9.24682 3.83331 8.78011V4.78011Z"
        stroke="#2565C8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  );
};

export default DataIcon;
