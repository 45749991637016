import React from 'react';

const CoinsStackedIcon = () => {
  return (
    <svg fill="none" height="41" viewBox="0 0 41 41" width="41" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5011 28.4466C20.5011 33.049 24.2321 36.78 28.8345 36.78C33.4368 36.78 37.1678 33.049 37.1678 28.4466C37.1678 23.8442 33.4368 20.1133 28.8345 20.1133C24.2321 20.1133 20.5011 23.8442 20.5011 28.4466ZM20.5011 28.4466C20.5011 26.5702 21.1213 24.8387 22.1678 23.4458V8.44662M20.5011 28.4466C20.5011 29.8222 20.8344 31.12 21.4247 32.2635C20.0206 33.4496 16.7775 34.28 13.0011 34.28C7.93853 34.28 3.83447 32.7876 3.83447 30.9466V8.44662M22.1678 8.44662C22.1678 10.2876 18.0637 11.7799 13.0011 11.7799C7.93853 11.7799 3.83447 10.2876 3.83447 8.44662M22.1678 8.44662C22.1678 6.60567 18.0637 5.11328 13.0011 5.11328C7.93853 5.11328 3.83447 6.60567 3.83447 8.44662M3.83447 23.4466C3.83447 25.2876 7.93853 26.78 13.0011 26.78C16.6495 26.78 19.8 26.0049 21.2755 24.8829M22.1678 15.9466C22.1678 17.7876 18.0637 19.28 13.0011 19.28C7.93853 19.28 3.83447 17.7876 3.83447 15.9466"
        stroke="#F79009"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  );
};

export default CoinsStackedIcon;
