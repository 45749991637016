import React, { FC, ReactNode } from 'react';

interface ChipProps {
  children: ReactNode;
}

const Chip: FC<ChipProps> = ({ children }) => {
  return (
    <div
      className="w-fit rounded-[32px] border border-gray-700 px-3 py-[3px] text-label-md font-[450] text-gray-25"
      style={{
        background: 'linear-gradient(180deg, #344054 0%, #475467 100%)',
      }}
    >
      {children}
    </div>
  );
};

export default Chip;
