import React from 'react';

const RedInfoCircle = () => {
  return (
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1062_20363)">
        <path
          d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
          stroke="#D92D20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1062_20363">
          <rect fill="white" height="12" width="12" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RedInfoCircle;
