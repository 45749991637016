import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useRedirectToHttps = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_FORCE_SSL !== 'true') return;

    if (window.location.protocol !== 'https:') {
      window.location.replace('https://' + window.location.host + location.pathname);
    }
  });
};

export default useRedirectToHttps;
