import React from 'react';

const PieChartIcon = () => {
  return (
    <svg fill="none" height="41" viewBox="0 0 41 41" width="41" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5 3.44678C22.6887 3.44678 24.8559 3.87787 26.878 4.71545C28.9001 5.55303 30.7375 6.78069 32.2851 8.32834C33.8327 9.87598 35.0604 11.7133 35.898 13.7354C36.7356 15.7575 37.1666 17.9248 37.1666 20.1135M20.5 3.44678V20.1134M20.5 3.44678C11.2952 3.44678 3.83331 10.9087 3.83331 20.1134C3.83331 29.3182 11.2952 36.7801 20.5 36.7801C29.7047 36.7801 37.1666 29.3182 37.1666 20.1135M20.5 3.44678C29.7047 3.44678 37.1666 10.9087 37.1666 20.1135M37.1666 20.1135L20.5 20.1134M37.1666 20.1135C37.1666 22.7436 36.5442 25.3365 35.3501 27.68C34.156 30.0235 32.4243 32.0511 30.2964 33.5971L20.5 20.1134"
        stroke="#12B76A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  );
};

export default PieChartIcon;
