import React, {
  FC,
  HTMLInputTypeAttribute,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { RedInfoCircle } from '../../assets/icons';

enum InputStatus {
  IDLE_EMPTY = 'idle-empty',
  IDLE_FULL = 'idle-full',
  FOCUSED = 'focused',
  ERROR = 'error',
  SUCCESS = 'success',
}

const inputStatusToWrapperClassNamesMap: Record<InputStatus, string> = {
  [InputStatus.IDLE_EMPTY]: '!border-b !border-gray-600',
  [InputStatus.IDLE_FULL]: '!border-b-[2px] !border-gray-700',
  [InputStatus.FOCUSED]: '!border-b-[2px] !border-brand-700',
  [InputStatus.ERROR]: '!border-b-[2px] !border-fireside-600',
  [InputStatus.SUCCESS]: '!border-b-[2px] !border-forest-500',
};

const inputStatusToPlaceholderClassNamesMap: Record<InputStatus, string> = {
  [InputStatus.IDLE_EMPTY]: '!text-gray-400',
  [InputStatus.IDLE_FULL]: '!text-gray-500',
  [InputStatus.FOCUSED]: '!text-brand-700',
  [InputStatus.ERROR]: '!text-fireside-600',
  [InputStatus.SUCCESS]: '!text-forest-500',
};

interface InputProps {
  disabled?: boolean;
  errorMessage?: string;
  icon?: ReactNode;
  isError?: boolean;
  isSuccess?: boolean;
  onChange: (value: string) => void;
  onIconClick?: () => void;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  value: string;
}

const Input: FC<InputProps> = ({
  value,
  onChange,
  placeholder,
  type,
  isError,
  errorMessage,
  isSuccess,
  disabled,
  icon,
  onIconClick,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [status, setStatus] = useState(InputStatus.IDLE_EMPTY);

  useEffect(() => {
    if (isError || errorMessage) {
      setStatus(InputStatus.ERROR);
      return;
    }

    if (isSuccess) {
      setStatus(InputStatus.SUCCESS);
      return;
    }

    if (isFocused) {
      setStatus(InputStatus.FOCUSED);
      return;
    }

    setStatus(value ? InputStatus.IDLE_FULL : InputStatus.IDLE_EMPTY);
  }, [errorMessage, isError, isFocused, isSuccess, value]);

  const handleClick = useCallback(() => {
    ref.current?.focus();
  }, []);

  return (
    <div className="flex cursor-text flex-col gap-3" onClick={handleClick}>
      <div
        className={`relative flex h-14 items-center gap-2.5 rounded-t bg-gray-50 px-2.5 ${inputStatusToWrapperClassNamesMap[status]} `}
      >
        {placeholder && (
          <div
            className={`pointer-events-none absolute left-2.5 top-1/2 -translate-y-1/2 font-inter text-sm font-[450] transition-all duration-75 ${inputStatusToPlaceholderClassNamesMap[status]} ${isFocused || value ? '!top-[8px] !translate-y-0 !text-xs' : ''}`}
          >
            {placeholder}
          </div>
        )}
        <div className="flex h-full w-full flex-col justify-end pb-2">
          <input
            className="z-1 w-full bg-transparent font-inter text-sm font-[450] text-gray-700"
            disabled={disabled}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => onChange(e.target.value || '')}
            onFocus={() => setIsFocused(true)}
            ref={ref}
            type={type}
            value={value}
          />
        </div>
        {icon && (
          <div className={`${onIconClick ? 'cursor-pointer' : ''}`} onClick={onIconClick}>
            {icon}
          </div>
        )}
      </div>
      {errorMessage && (
        <div className="flex items-center gap-1">
          <RedInfoCircle />
          <div className="whitespace-pre-line text-left font-inter text-xs font-[450] text-fireside-600">
            {errorMessage}
          </div>
        </div>
      )}
    </div>
  );
};

export default Input;
